exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-moonsama-composer-tsx": () => import("./../../../src/pages/moonsama-composer.tsx" /* webpackChunkName: "component---src-pages-moonsama-composer-tsx" */),
  "component---src-pages-moonsama-metaverse-tsx": () => import("./../../../src/pages/moonsama-metaverse.tsx" /* webpackChunkName: "component---src-pages-moonsama-metaverse-tsx" */),
  "component---src-pages-multiverse-portal-tsx": () => import("./../../../src/pages/multiverse-portal.tsx" /* webpackChunkName: "component---src-pages-multiverse-portal-tsx" */),
  "component---src-pages-pondsama-tsx": () => import("./../../../src/pages/pondsama.tsx" /* webpackChunkName: "component---src-pages-pondsama-tsx" */),
  "component---src-pages-raresama-marketplace-tsx": () => import("./../../../src/pages/raresama-marketplace.tsx" /* webpackChunkName: "component---src-pages-raresama-marketplace-tsx" */),
  "component---src-pages-sama-token-tsx": () => import("./../../../src/pages/sama-token.tsx" /* webpackChunkName: "component---src-pages-sama-token-tsx" */),
  "component---src-pages-wildsama-tsx": () => import("./../../../src/pages/wildsama.tsx" /* webpackChunkName: "component---src-pages-wildsama-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

